<template>
  <div class="notification-center tw-flex tw-flex-col tw-gap-2">
    <Notify
      v-for="notification in notifications"
      :key="notification.RefID"
      :notification="notification"
      @accept="onAccept(notification)"
      @reject="onReject(notification)"
      @view="onViewRoomDetail(notification)"
      @close="removeNotification(notification.RefID)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { EventBus } from "@/event-bus";
export default {
  name: "NotificationCenter",
  components: {
    Notify: () => import("./Notify.vue"),
  },
  data() {
    return {
      notifications: [],
    };
  },
  computed: {
    ...mapGetters({
      consoleSocket: "chat/consoleSocket",
      user: "userInfo",
    }),
  },
  watch: {
    consoleSocket: {
      immediate: true,
      handler: function(value) {
        if (value) {
          this.consoleSocket.addEventListener("message", (event) => {
            const data = JSON.parse(event.data);
            if (data.Type === "agent-join-request-notification") {
              console.log("Notification center, new event", data);
              this.notifications.push(data);
            }
            if (data.Type === "agent-join-accepted-notification") {
              console.log("Notification center, new event", data);
              this.notifications.push(data);
            }
            if (data.Type === "agent-join-rejected-notification") {
              console.log("Notification center, new event", data);
              this.notifications.push(data);
            }
            if (
              data.Type === "RoomReopened" ||
              data.Type === "RoomClosed" ||
              data.Type === "RoomAbandoned"
            ) {
              // EventBus.$emit("update-local-room", data.RoomID);
            }
          });
        }
      },
    },
  },
  methods: {
    onAccept(notif) {
      console.log("onAccept", notif);
      this.removeNotification(notif.RefID);
      this.$store.dispatch("chat/acceptToJoin", {
        ...notif,
        AgentID: this.user.userCode,
        AgentName: this.user.userFirstName,
      });
    },
    onReject(notif) {
      console.log("onReject", notif);
      this.removeNotification(notif.RefID);
      this.$store.dispatch("chat/rejectToJoin", {
        ...notif,
        AgentID: this.user.userCode,
        AgentName: this.user.userFirstName,
      });
    },
    onViewRoomDetail(notif) {
      console.log("onViewRoomDetail", notif);
      this.$store.commit("chat/setSelectedRoomID", notif.RoomID);
      if (notif.Type !== "agent-join-request-notification") {
        this.removeNotification(notif.RefID);
      }
    },
    removeNotification(id) {
      this.notifications.splice(
        this.notifications.findIndex((ntf) => ntf.RefID === id),
        1
      );
    },
  },
  beforeDestroy() {
    this.consoleSocket.close();
  },
};
</script>

<style></style>
